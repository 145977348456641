import React, { Component, Suspense } from 'react';
import './Navbar.sass';
import Config from '../../../base/config/Config';
import PropTypes from 'prop-types';
import Button from './../button/Button';
import Dropdown from './../dropdown/Dropdown';
import Title from './../title/Title';
import SmallText from './../smallText/Smalltext';
import i18n from '../../../i18n';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import NewArea from '../newArea/NewArea';
import DataArray from '../../../base/config/DataArray';
import Message from '../message/Message';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { ButtonColor: 'Transparentfourth' };
    }

    render() {

        var currentLanguage = Cookies.get('currentLanguage');

        var company = DataArray.company;
        var solutions = DataArray.categories;

        var resources = DataArray.resources;

        const type = this.props.type || 'Primary';

        var classNavValue = 'Navbar ' + type;

        var buttonType = '';
        var buttonTypeLogin = '';
        var buttonTypeNormal = '';
        var swonkieLogo = '';
        var swonkieLogoHamburger = require('./../../../resources/icons/swonkieLogo/swonkieBlack.svg');


        if (type === 'Primary') {
            buttonType = 'Transparent';
            buttonTypeLogin = 'Transparent';
            buttonTypeNormal = 'Secundary';
            swonkieLogo = require('./../../../resources/icons/swonkieLogo/swonkieWhite.svg');
        }
        else {
            buttonType = 'TransparentTertiary';
            buttonTypeLogin = 'TransparentSecundary';
            buttonTypeNormal = 'Primary';
            swonkieLogo = require('./../../../resources/icons/swonkieLogo/swonkieBlack.svg');
        }

        var navLinks = React.createRef();
        var menuLine = React.createRef();

        var menuAnimation = () => {
            navLinks.current.classList.toggle('open');
            menuLine.current.classList.toggle('open');
            var banner = document.getElementsByClassName('socialMediaDay');
            if (typeof banner[0] !== 'undefined') {
                banner[0].style.display = banner[0].style.display === 'none' ? '' : 'none';
                var navbarArea = document.getElementsByClassName('NavAndBannerArea');
                navbarArea[0].style.paddingTop = navbarArea[0].style.paddingTop === '0px' ? '24px' : '0px';
            }
        };

        var html =
            <header className={'NavAndBannerArea ' + type}>
                <header className={classNavValue} >
                    <div className="NavbarContent">
                        <div className="leftButtonArea">
                            <div className="NavbarLogo">
                                <Link to="/"> <img src={swonkieLogo} className="swonkieLogo" alt="swonkieLogo" /> </Link>
                            </div>
                            <Dropdown type="Primary" buttonType={buttonType} text={<div style={{ display: 'flex', alignItems: 'center' }}>{i18n.t('Solutions')} {currentLanguage !== 'fr' && <NewArea style={{ marginLeft: '4px' }} />}</div>} >
                                <div className="FirstDropdownContent" >
                                    {solutions.map((category) => {
                                        if (category.navbarSpecial)
                                            return <div className='FirstDropdownContentSpecialContent'>
                                                <img style={{ marginBottom: '24px', height: '24px' }} src={category.navbarImage} />
                                                <NewArea style={{ marginBottom: '16px' }} type='Andie' />
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                                    <SmallText type='Primary' font='SofiaProRegular' size='Bigger' text={category.text} />
                                                    <SmallText type='Primary' font='Roboto' size='Big' text={category.navbarMessage} />
                                                </div>
                                                <Button to={`/${currentLanguage}/solutions/${category.href}`} size='' text={category.navbarButton} />
                                            </div>;
                                        return null;
                                    })}
                                    <div className="FirstDropdownContentLeft">
                                        {solutions.map((category, key) => {
                                            var image = category.icon;
                                            if (category.navbarSpecial)
                                                return null;
                                            return (
                                                <div className="FirstDropdownContentLeftContent" key={key}>
                                                    <Link to={`/${currentLanguage}/solutions/${category.href}`} >
                                                        <div className="FirstDropdownContentLeftImage">
                                                            <img alt="iconImage" src={image} />
                                                            {category.isNew ?
                                                                <div className="NavbarNewTextArea">
                                                                    <NewArea />
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="FirstDropdownContentLeftTitle">
                                                            <SmallText size="Bigger" type="Fourth" font="SofiaProBold" text={category.text} />
                                                        </div>
                                                        <div className="FirstDropdownContentLeftMessage" >
                                                            <SmallText size="Medium" font="Roboto" type="Secundary" text={category.navbarMessage} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="FirstDropdownContentRight">
                                        <div className="FirstDropdownContentRightTitle">
                                            <Title size="Small" type="Tertiary" text={i18n.t('One Powerfull Solution for All Your Social Media')} />
                                        </div>
                                        <div className="FirstDropdownContentRightButton">
                                            <a href={`${Config.appHost}register`}> <Button type="Primary" size="Small" text={i18n.t('I want')} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </Dropdown>
                            <Link to={`/${currentLanguage}/pricing`} > <Button size="Small" type={buttonType} text={i18n.t('Pricing')} /> </Link>
                            <Dropdown type="Primary" buttonType={buttonType} text={company.title} >
                                <div className="SecondDropdownContent" >
                                    {company.buttons.map((button, key) => {
                                        if (button.showNavbar) {
                                            if (button.text === 'Blog')
                                                return <div className="SecondDropdownContentButton" key={key} > <a key={key} href={`${Config.blog}?utm_source=swonkie_homepage&utm_medium=organic&utm_campaign=menu_swonkie`} target="_blank" rel="noopener noreferrer"> <Button key={key} size="Small" type="TransparentHover" text={button.text} /> </a> </div>;
                                            else
                                                return <div className="SecondDropdownContentButton" key={key} > <Link key={key} to={`/${currentLanguage}/${button.to}`}> <Button key={key} size="Small" type="TransparentHover" text={button.text} /> </Link> </div>;
                                        }
                                        return null;
                                    })}
                                </div>
                            </Dropdown>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <Dropdown type="Primary" buttonType={buttonType} text={resources.title}>
                                    <div className="SecondDropdownContent" >
                                        {resources.buttons.map((button, key) => {
                                            if (button.to.includes('http'))
                                                return <div className="SecondDropdownContentButton" key={key} > <a key={key} href={`${button.to}`} target="_blank" rel="noopener noreferrer"> <Button size="Small" type="TransparentHover" text={button.text} /> </a> </div>;
                                            else
                                                return <div className="SecondDropdownContentButton" key={key} > <Link key={key} to={`/${currentLanguage}/${button.to}`}> <Button size="Small" type="TransparentHover" text={button.text} /> </Link> </div>;
                                        })}
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="rightButtonArea">
                            <a href={`${Config.appHost}login`} > <Button noOverflow size="Small" type={buttonTypeLogin} text={i18n.t('Login')} /> </a>
                            <a href={`${Config.appHost}register`} > <Button noOverflow size="Small" type={buttonTypeNormal} text={i18n.t('Start Free')} /> </a>
                            {type === 'Secundary' ? <Link to={`/${currentLanguage}/contact`}> <Button noOverflow size="Small" type="Tertiary" text={i18n.t('Request a Demo')} /> </Link> : null}
                        </div>

                        <div className='hamburgerArea'>
                            <div className="hamburgerImageArea">
                                <Link to={`/${currentLanguage}/`} > <img src={swonkieLogo} className="swonkieLogo" alt="swonkieLogo" /> </Link>
                            </div>
                            <nav>
                                <div className="menu" onClick={menuAnimation} ref={menuLine} >
                                    <div className="Menuline" />
                                    <div className="Menuline" />
                                    <div className="Menuline" />
                                </div>

                                <div className="menuLink" ref={navLinks}>
                                    <div className="navImage">
                                        <Link to={`/${currentLanguage}/`}> <img src={swonkieLogoHamburger} alt="swonkieLogo" /> </Link>
                                    </div>
                                    <div className="navContent">

                                        <div className="navLink">
                                            <Dropdown type="Secundary" buttonType="SofiaProBlackSmall" text={i18n.t('Solutions')} >
                                                <div className="DropdownContent">
                                                    {solutions.map((solutions, key) => {
                                                        return <Link key={key} to={`/${currentLanguage}/solutions/${solutions.href}`}> <Button onClick={menuAnimation} key={key} size="Small" type="TransparentHover" text={solutions.text} /> </Link>;
                                                    })}
                                                </div>
                                            </Dropdown>
                                            <Link to={`/${currentLanguage}/pricing`} ><Button onClick={menuAnimation} size="Small" type="SofiaProBlackSmall" text={i18n.t('Pricing')} /></Link>
                                            <Dropdown type="Secundary" buttonType="SofiaProBlackSmall" text={company.title} >
                                                <div className="DropdownContent">
                                                    {company.buttons.map((company, key) => {
                                                        if (company.text.indexOf('2020')) {
                                                            if (company.text === 'Blog')
                                                                return <a key={key} href={company.to} target="_blank" rel="noopener noreferrer" > <Button onClick={menuAnimation} key={key} size="Small" type="TransparentHover" text={company.text} /> </a>;
                                                            else
                                                                return <Link key={key} to={`/${currentLanguage}/${company.to}`}> <Button onClick={menuAnimation} key={key} size="Small" type="TransparentHover" text={company.text} /> </Link>;
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </Dropdown>
                                            <Dropdown type="Secundary" buttonType="SofiaProBlackSmall" text={resources.title}>
                                                <div className="DropdownContent">
                                                    {resources.buttons.map((button, key) => {
                                                        if (button.to.includes('http'))
                                                            return <a key={key} href={`${button.to}`} target="_blank" rel="noopener noreferrer"> <Button onClick={menuAnimation} size="Small" type="TransparentHover" text={button.text} /> </a>;
                                                        else
                                                            return <Link key={key} to={`/${currentLanguage}/${button.to}`}> <Button size="Small" onClick={menuAnimation} type="TransparentHover" text={button.text} /> </Link>;
                                                    })}
                                                </div>
                                            </Dropdown>
                                        </div>
                                        <div className="navLogin">
                                            <div className="navLoginButton">
                                                <a href={`${Config.appHost}register`}> <Button size="Small" type="Primary" text={i18n.t('Start Free')} /> </a>
                                                <a href={`${Config.appHost}login`} > <Button size="Small" type="Transparentfourth" text={i18n.t('Login')} /> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </header>;

        return <Suspense fallback={null}>{html}</Suspense>;
    }

}

Navbar.propTypes = {
    type: PropTypes.oneOf(['Primary', 'Secundary'])
};

Navbar.defaultProps = {
    type: 'Secundary'
};

export default Navbar;
