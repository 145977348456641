import React, { lazy } from 'react';
import './Home.sass';
import homeImage1 from './../../../../../resources/images/home/landing_hero1.webp';
import homeImage2 from './../../../../../resources/images/home/landing_hero2.webp';
import check from './../../../../../resources/icons/check.svg';
import firstLogo from './../../../../../resources/icons/logos/benfica.webp';
import secondLogo from './../../../../../resources/icons/logos/groupm.webp';
import thirdLogo from './../../../../../resources/icons/logos/havas.webp';
import fourthLogo from './../../../../../resources/icons/logos/impresa.webp';
import i18n from '../../../../../i18n';
import Config from '../../../../../base/config/Config';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import DataArray from '../../../../../base/config/DataArray';
import LazyLoad from 'react-lazyload';
const Button = lazy(() => import('./../../../../component/button/Button'));
const Title = lazy(() => import('../../../../component/title/Title'));
const Message = lazy(() => import('./../../../../component/message/Message'));
const SmallText = lazy(() => import('./../../../../component/smallText/Smalltext'));

function Home() {
    var dataArray = DataArray.details;
    var currentLanguage = Cookies.get('currentLanguage');

    return (
        <div className="homeArea">
            <div className="homeAreaTop">
                <div className="homeMessageArea">
                    <div className="homeTitle">
                        <Title size="Big" type="Primary" text={i18n.t('Improve your Performance on Social Media, Seriously.')} />
                    </div>
                    <div className="homeMessage">
                        <Message size="Big" type="Primary" text={i18n.t('Publish and Schedule. Analytics and Competitors. Influence Marketing. Inbox and Community Management. Team Workflow. Social Listening.')} />
                        <SmallText size="Bigger" type="Primary" font="OpenSans" text={i18n.t('READY?')} />
                    </div>
                </div>
                <div className="homeButtonArea">
                    <a href={`${Config.appHost}register`}><Button size="Big" type="Tertiary" text={i18n.t('Yes, I Want to Start')} /> </a>
                    <Link to={`/${currentLanguage}/contact`}> <Button size="Big" type="UnderlineTransparent" text={i18n.t('I Prefer to Book a Demo')} /> </Link>
                </div>
                <div className="hometextArea">
                    {dataArray.map((category, key) => {
                        return (
                            <div className="homeText" key={key}>
                                <img src={check} alt="check" />
                                <SmallText size="Big" type="Primary" font="OpenSans" text={category.text} />
                            </div>
                        );
                    })}
                </div>
                <div className="homeImageArea">
                    <div className="homeImageLeftArea">
                        <img alt="" src={homeImage1} />
                    </div>
                    <div className="homeImageRightArea">
                        <img alt="" src={homeImage2} />
                    </div>
                </div>
                <div className="homeAreaBottom">
                    <div className="homeLogoArea">
                        <LazyLoad offset={200} height={50}>
                            <img src={firstLogo} alt="googleLogo" />
                            <img src={secondLogo} alt="nikeLogo" />
                            <img src={thirdLogo} alt="netflixLogo" />
                            <img src={fourthLogo} alt="martiniLogo" />
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
