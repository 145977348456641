import React, { Component, lazy, Suspense } from 'react';
import './LandingPage.sass';
import Cookies from 'js-cookie';
import Utils from '../../../base/Utils';
import OpenSocial from './subpages/openSocial/OpenSocial';
import Navbar from '../../component/navBar/Navbar';
import Home from './subpages/home/Home';
import Andie from './subpages/andie/Andie';
const InstagramStories = lazy(() => import('./subpages/instagramStories/InstagramStories'));
const Footer = lazy(() => import('./../../component/footer/Footer'));
const Plan = lazy(() => import('./../../component/plan/Plan'));
const TypeClient = lazy(() => import('./subpages/typeClient/TypeClient'));
const Category = lazy(() => import('./subpages/category/Category'));
const Witness = lazy(() => import('./subpages/witness/Witness'));
const CostumerStory = lazy(() => import('./subpages/costumerStory/CostumerStory'));
const ToJoin = lazy(() => import('../../component/toJoin/ToJoin'));
const GetStarted = lazy(() => import('./subpages/getStarted/GetStarted'));
const Report = lazy(() => import('./subpages/report/Report'));
//const Blog = lazy(() => import('./subpages/blog/Blog'));
//const AcademyForm = lazy(() => import('../../component/swonkieAcademyForm/AcademyForm'));


class LandingPage extends Component {

    defaultState = { navbar: 'Primary', planPrices: {} }

    constructor(props) {
        super(props);
        this.home = React.createRef();
        this.state = this.defaultState;

        this.category = React.createRef();
    }

    componentDidMount() {
        var date = new Date();
        var maxDate = new Date('2023', '6', '31', '23', '59');
        if (date <= maxDate)
            this.setState({ navbar: 'Secundary' });
        else
            window.addEventListener('scroll', this.handleScroll);

        var promoCode = this.props.match.params.code || null;

        if (Utils.planData == null) {
            Utils.getPlanPrices(() => this.fillPlanData(), promoCode);
        } else {
            this.fillPlanData();
        }
        Utils.handleGoToHash();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    fillPlanData() {
        this.setState({ planPrices: Utils.planData });

        if (typeof Utils.planData.promoCode !== 'undefined' && Utils.planData.promoCode !== '') {
            if (typeof Cookies.get('promoCode') !== 'undefined') {
                Cookies.remove('promoCode', { expires: 30 });
            }

            const assign = 'promoCode=' + escape(Utils.planData.promoCode) + ';';
            const d = new Date();
            d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString() + ';';
            const path = 'path=/;';
            const domain = 'domain=.' + window.location.hostname + ';';
            document.cookie = assign + expires + path + domain;
            window.history.replaceState({}, document.title, `/${Cookies.get('currentLanguage')}`);

            if (typeof this.props.promoCodeCallback !== 'undefined') {
                this.props.promoCodeCallback(Utils.planData.promoCode);
            }
        }
    }

    handleScroll = () => {
        if (window.scrollY > 900) {
            this.setState({ navbar: 'Secundary' });
        } else {
            this.setState({ navbar: 'Primary' });
        }
    }

    render() {
        var path = this.props.location.pathname;
        const min = 1;
        const max = 2;
        const rand = Math.floor(Math.random() * (max - min + 1) + min);
        return (
            <div className="MainPageArea">
                <Navbar type={this.state.navbar} />
                <Home ref={this.home} />
                <TypeClient />
                <div ref={this.category}>
                    <Category thisRef={this.category} />
                </div>
                <ToJoin />
                <Plan planPrices={this.state.planPrices} />
                <Witness />
                <CostumerStory />
                <GetStarted />
                <Report />
                {/*<Blog />*/}
                {rand < 2 ?
                    <OpenSocial />
                    :
                    <InstagramStories />
                }
                <Footer path={path} />
            </div>
        );
    }
}

export default LandingPage;
